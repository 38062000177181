<template>
    <div class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <div class="content">
                    <h1>{{ $t('settings.header') }}</h1>
                    <div class="buttons">
                        <b-button
                            tag="router-link"
                            :to="{ name: 'Changelog' }"
                            type="is-link"
                        >
                            Changelog
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Settings'
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}
</style>
